import { getCurrentLanguage, getTranslationFn, type TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { loadAsyncData, setupInternalData } from "@utils/vue-migration/common/gaContext/gaContextInternals";
import { getFlags } from "@utils/vue-migration/common/gaContext/gaContextFlags";
// eslint-disable-next-line no-restricted-imports
import { type GaContextDataSync, type GaContextFlags, Language, type RoleResource, type RoleResourceConstraint } from "@newgenerated/shared/schema";

/*
 * Data that gets loaded for every Vue instance.
 */
export const dataKeysSync = ["currentLanguage", "flags"] satisfies (keyof GaContextDataSync)[];

/*
 * Data that can be loaded async if needed.
 */
export type GaContextDataAsync = {
  roleResourceConstraints: RoleResourceConstraint[];
} & {
  [Property in Language]: Map<string, string>;
};
export const dataKeysAsync = ["roleResourceConstraints", ...Language.getValues()] satisfies (keyof GaContextDataAsync)[];

export type AsyncLoaders = {
  [Property in keyof GaContextDataAsync]: () => Promise<GaContextDataAsync[Property]>;
};

/**
 * This needs to run before any Vue instances are created.
 */
export async function setupGaContext(syncData: GaContextDataSync, asyncLoaders: AsyncLoaders): Promise<void> {
  setupInternalData(syncData, asyncLoaders);

  // init TMS texts of current language
  await loadAsyncData(getCurrentLanguage());
}

/*
 * Usage of the context
 */

/**
 * Convention:
 * - every property is a function.
 * - async function names start with "load".
 */
export type GaContext = {
  t: TranslationFn;
  currentLanguage: () => Language;
  flags: () => GaContextFlags;
  loadTranslation: (lang: Language) => Promise<TranslationFn>;
  hasAccess: (resource: RoleResource, language?: Language, corporateId?: number) => Promise<boolean>;
};

export function useGaContext(): GaContext {
  const lang = getCurrentLanguage();

  const t = getTranslationFn(lang);

  const currentLanguage = (): Language => getCurrentLanguage();

  const loadTranslation = async (lang: Language): Promise<TranslationFn> => {
    await loadAsyncData(lang);
    return getTranslationFn(lang);
  };

  const loadRoleResourceConstraint = async (): Promise<RoleResourceConstraint[]> => {
    return await loadAsyncData("roleResourceConstraints");
  };

  const hasAccess = async (resource: RoleResource, language?: Language, corporateId?: number): Promise<boolean> => {
    const constraints = await loadRoleResourceConstraint();
    const constraint = constraints.find((roleResourceConstraint) => roleResourceConstraint.resource === resource);

    const hasLanguage = (constraint: RoleResourceConstraint, language?: Language): boolean => {
      if (language === undefined || constraint.languages.length === 0) {
        return true;
      }
      return constraint.languages.includes(language);
    };
    const hasCorporation = (constraint: RoleResourceConstraint, corporationId: number | undefined): boolean => {
      if (corporationId === undefined || constraint.corporateIds.length === 0) {
        return true;
      }
      return constraint.corporateIds.includes(corporationId);
    };
    return constraint !== undefined && hasLanguage(constraint, language) && hasCorporation(constraint, corporateId);
  };

  return { t, currentLanguage, loadTranslation, hasAccess: hasAccess, flags: getFlags };
}
