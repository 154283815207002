export const generatedRoutes = [
   { 
       path: "design-spa", 
       component: async () => await import("@/spa/pages/Demo1.vue"),
       name: "Demo1",
       meta: {
           title: "Demo1",
       },
   },
   { 
       path: "design-spa/demo", 
       component: async () => (await import("@/spa/pages/Demo2")).Demo2,
       name: "Demo2",
       meta: {
           title: "Demo2",
       },
   },
];
